import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import whiteLogo from "../images/functurion_logo_white_transparent.png";
const Header = () => (
  <header
    style={{
      background: `black`,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start'
    }}
  >

      <img  style={{
        height: `7rem`,
        width: `7rem`,
        padding: `1.45rem 1.0875rem`,
      }} src={whiteLogo} alt="functurion white logo" />

      <div style={{padding: `3rem 0.5rem`}}>
      <Link
        to="/"
        style={{
          fontSize: "25px",
          color: `white`,
          textDecoration: `none`,
        }}
      >
        functurion
      </Link>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `functurion.com`,
}

export default Header
